import { Block } from "@/blocks/base";
import { BlockContainer } from "@/blocks/base/block-container";
import { RemoteImage } from "@/uikit/remote-image";
import React from "react";
import { Typography } from "@/uikit/Typography";

import { imageStyle, titleStyle, imgWrapperStyle, iconsWrapperStyle } from "./companies.css";
import { FlexContainer } from "@/uikit/container";

const Companies = Block("companies", (props) => {
  return (
    <BlockContainer defaultTheme="light" settings={props.settings} layout="centeredColumn">
      <FlexContainer direction="column">
        <Typography.h4 className={titleStyle}>{props.title.toUpperCase()}</Typography.h4>
        <FlexContainer alignItems="center" justifyContent="center" wrap="wrap" className={iconsWrapperStyle}>
          {props.items.map(({ label, retinaIcon }) => (
            <div key={label} className={imgWrapperStyle}>
              <RemoteImage
                className={imageStyle}
                image={retinaIcon}
                alt={label}
                sizes={{
                  mobile: "50vw",
                  tablet: "10vw",
                  desktop: "10vw",
                }}
              />
            </div>
          ))}
        </FlexContainer>
      </FlexContainer>
    </BlockContainer>
  );
});

export default Companies;
